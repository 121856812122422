@use '../../styles/vars';

.sectionListBtnSubscriptions {
  .listBtnSubscriptions {
    display: flex;

    .containerBtnSubscription {
      position: relative;
      z-index: 10;
      padding: 0 0.7em 0;

      .subscriptionBackground {
        position: absolute;
        inset: 0;
        z-index: 5;
        background: vars.$color8;
        transform: translateY(-10px);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        height: 0;
        transition: height 0.3s ease-in-out;
      }

      .subscriptionSelected {
        position: absolute;
        inset: 0;
        top: 100%;
        z-index: 5;
        background: vars.$color8;
        transform: translateY(10px);
        height: 5px;
        border-radius: 8px;
      }

      &:hover {
        .subscriptionBackground {
          height: 100px;
        }
      }
    }

    .btnSubscription {
      display: flex;
      align-items: center;
      width: 2.75em;
      height: 2.75em;
      background: none;
      border: none;
      cursor: pointer;
      position: relative;
      z-index: 10;

      &IsCurrentSelected {
        pointer-events: none;
      }

      &Flag {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      background: vars.$color3;
    }

    &::-webkit-scrollbar-thumb {
      background: vars.$color8;
      border-radius: 8px;
    }
  }
}

@media screen and (orientation: portrait) {
  .sectionListBtnSubscriptions {
    .listBtnSubscriptions {
      display: none;
    }
  }
}
